import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import UAParser from 'ua-parser-js';

import Header from '../../partials/Header';
import SharedIndividualRoutes from './SharedIndividualRoutes';
import SharedResultsBox from './SharedResultsBox';
import SharedRouteModal from './SharedRouteModal'
import SplitUrlModal from '../SplitUrlModal/SplitUrlModal'

import Axios from 'axios'

function SharedRoute(props) {

    const { dataurl, routesurl, coordinatesurl } = useParams();
    const { routeid } = useParams();

    const [splitUrlModalOpen, setSplitUrlModalOpen] = useState(false)

    const parser = new UAParser();
    const result = parser.getResult();
    const deviceType = result.device.type;
    let isMobile = false
    if (deviceType != 'desktop' && deviceType != 'mobile' && deviceType != 'tablet') {
        if (window.innerWidth < 768) {
            isMobile = true
        }
    } else {
        if (deviceType == 'mobile' || deviceType == 'tablet') {
            isMobile = true
        }
    }

    //const [modalOpen, setModalOpen] = useState(isMobile);
    const [modalOpen, setModalOpen] = useState(false); // remove (and uncomment above) this when deeplinking is ready
    const [os, setOs] = useState(result.os.name)

    const [directionsData, setDirectionsData] = useState({
        curatedLinks: {googleUrl: "", appleUrl: "", wazeUrl: ""},
        routeOrder: [],
        totalDistance: 0,
        totalTime: 0
    })

    const [mapSelected, setMapSelected] = useState({
        googleMaps: true,
        appleMaps: false,
        waze: false
    })

    let navApp = 'google'
    if (mapSelected.appleMaps) {
        navApp = 'apple'
    } else if (mapSelected.waze){
        navApp = 'waze'
    }

    const [loaded, setLoaded] = useState(false)

    // // code to create link
    // let addressList = [
    //     'UTD Activity Center, 800 W Campbell Rd, Richardson, TX 75080',
    //     'Northside Apartments, 3000 Northside Blvd, Richardson, TX 75080',
    //     'Piada Italian Street Food, 1551 E Renner Rd #800, Richardson, TX 75082',
    //     'Heritage Farmstead Museum, 1900 W 15th St, Plano, TX 75075',
    //     'Plano, Texas',
    //     'Prairie Creek Park, 2400 W Prairie Creek Dr, Richardson, TX 75080',
    //     'UTD Activity Center, 800 W Campbell Rd, Richardson, TX 75080',
    //     'Point North Park, 725 Synergy Park Blvd, Richardson, TX 75080',
    //     'Golf Ranch Dallas, 3570 Waterview Pkwy, Richardson, TX 75080',
    //     'Custer Park, 701 W Renner Rd, Richardson, TX 75080',
    //     'Texas Archery Academy, 18325 Waterview Pkwy building B, ground floor, Dallas, TX 75252',
    //     'K1 Speed - Indoor Go Karts, Corporate Event Venue, Team Building Activities, 677 W Campbell Rd A, Richardson, TX 75080',
    //     'The Home Depot, 1224 N Central Expy, Plano, TX 75074'
    // ]
    // let totalDistance = 125
    // let totalTime = 150
    // function createLink(addressList, totalDistance, totalTime) {
    //     let baseUrl = 'http://routora.com/shared-route/'
    //     baseUrl = 'http://localhost:3000/shared-route/'

    //     baseUrl += (totalDistance + '+' + totalTime + '/')

    //     for (let i = 0; i < addressList.length; i++) {
    //         if (i == addressList.length-1) {
    //             baseUrl += encodeURIComponent(addressList[i])
    //         } else {
    //             baseUrl += encodeURIComponent(addressList[i]) + '+'
    //         }
    //     }

    //     console.log(baseUrl)
    // }

    useEffect(() => {

        if (routeid && routeid != null) {
            dbMethod()
        } else {
            urlMethod()
        }
        

    }, []);

    function urlMethod() {
        // unpack url
        let dataUrlSplit = dataurl.split('+')
        let dist = dataUrlSplit[0]
        let time = dataUrlSplit[1]
        console.log('totalDistance: ' + dist)
        console.log('totalTime: ' + time)

        let directions = {
            curatedLinks: {googleUrl: "", appleUrl: "", wazeUrl: ""},
            routeOrder: [],
            totalDistance: dist,
            totalTime: time
        }

        let googleUrl = ''
        let appleUrl = ''
        let addressUrlList = routesurl.split('+')
        // START ADDRESS
        let startAddress = addressUrlList[0]
        googleUrl = 'https://www.google.com/maps/dir/' + startAddress + '/'
        appleUrl = 'http://maps.apple.com/?saddr=' + startAddress
        let googleStartLink = 'https://www.google.com/maps/dir/?api=1&destination=' + startAddress + '&travelmode=' + 'd'
        let appleMapsStartLink = 'http://maps.apple.com/?address=' + startAddress
        let wazeStartLink = 'https://www.waze.com/ul?q='+ startAddress +'&navigate=yes'
        let soloUrls = {
            googleUrl: googleStartLink,
            appleUrl: appleMapsStartLink,
            wazeUrl: wazeStartLink
        }
        directions.routeOrder.push({address: decodeURIComponent(startAddress), soloUrls: soloUrls})
        // ORDERED STOPS
        let googleLink
        let appleMapsLink
        let wazeLink
        for (let i = 1; i < addressUrlList.length-1; i++) {
            googleUrl += addressUrlList[i] + '/'
            appleUrl += '&daddr=' + addressUrlList[i]
            googleLink = 'https://www.google.com/maps/dir/?api=1&destination=' + addressUrlList[i] + '&travelmode=' + 'd'
            appleMapsLink = 'http://maps.apple.com/?address=' + addressUrlList[i]
            wazeLink = 'https://www.waze.com/ul?q='+ addressUrlList[i] +'&navigate=yes'
            soloUrls = {
                googleUrl: googleLink,
                appleUrl: appleMapsLink,
                wazeUrl: wazeLink
            }
            directions.routeOrder.push({address: decodeURIComponent(addressUrlList[i]), soloUrls: soloUrls})
        }
        // DESTINATION ADDRESS
        let destinationAddress = addressUrlList[addressUrlList.length-1]
        googleUrl += destinationAddress + '/'
        appleUrl += '&daddr=' + destinationAddress + '&dirflg=d'
        let googleEndLink = 'https://www.google.com/maps/dir/?api=1&destination=' + destinationAddress + '&travelmode=' + 'd'
        let appleMapsEndLink = 'http://maps.apple.com/?address=' + destinationAddress
        let wazeEndLink = 'https://www.waze.com/ul?q='+ destinationAddress +'&navigate=yes'
        soloUrls = {
            googleUrl: googleEndLink,
            appleUrl: appleMapsEndLink,
            wazeUrl: wazeEndLink
        }
        directions.routeOrder.push({address: decodeURIComponent(destinationAddress), soloUrls: soloUrls})

        directions.curatedLinks = {
            googleUrl: googleUrl, 
            appleUrl: appleUrl, 
            wazeUrl: googleUrl
        }

        console.log(directions)
        setDirectionsData(directions)
        setLoaded(true)
    }

    async function dbMethod() {

        console.log('Route ID -> ', routeid)
        try {
            Axios.post(`https://api.routora.com/get-route/${routeid}`).then((response) => {
                // route should be saved and id used to save it should be passed back
                let routeData = response.data.routeData
                console.log(routeData)

                setDirectionsData(routeData)
                setLoaded(true)
            })
        } catch (error) {
            alert('Could not retrieve route data. Please contact info@routora.com if this is a recurring problem')
        }

    }

    let content = (
        <div>
            Loading...
        </div>
    )

    if (loaded) {
        content = (
            <div>
                <SharedResultsBox
                    directionsData={directionsData}
                    mapSelected={mapSelected}
                    setSplitUrlModalOpen={setSplitUrlModalOpen}
                ></SharedResultsBox>

                <SharedIndividualRoutes
                    directionsData={directionsData}
                    setMapSelected={setMapSelected}
                    mapSelected={mapSelected}
                ></SharedIndividualRoutes>
            </div>
        )
    }

    return (
        <div>
            {splitUrlModalOpen &&
                <SplitUrlModal
                    setOpenModal={setSplitUrlModalOpen}
                    directionsData={directionsData}
                    navApp={navApp}
                ></SplitUrlModal>
            }
            {modalOpen && 
            <SharedRouteModal 
                setOpenModal={setModalOpen}
                os={os}
                routeid={routeid}
            ></SharedRouteModal>}
            <Header></Header>
            <div className="h-20"></div>

            { content }
        </div>
    );
}

export default SharedRoute;

