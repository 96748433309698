import React from 'react';
import { useHistory } from 'react-router-dom';

function SharedRouteModal(props) {

    const history = useHistory();
    console.log(props.os)

    const openAppLink = () => {
      // com.routora.app://shared-route/cb576ff6258acd2db04a8df9163fb4fc
      console.log(`com.routora.app://shared-route/${props.routeid}`)

    };
    

  return (
    <div className="modalBackground">
      <div className="modalContainer shared">
            <div className="body text-2xl text-gray-600">Open route in Routora App?</div>
            <div className="footer">
            <div
                onClick={() => {
                    props.setOpenModal(false);
                }}
                id="cancelBtn"
                className="btn-sm text-gray-100 bg-myred-500 hover:bg-myred-600 cursor-pointer m-3 w-44"
            >
                Stay
            </div>
            <div className="btn-sm text-gray-100 bg-blue-500 hover:bg-blue-600 cursor-pointer m-3 w-44" onClick={openAppLink}>Go</div>
        </div>
      </div>
    </div>
  );
}

export default SharedRouteModal;