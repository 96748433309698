import { createContext, useState, useEffect, useContext } from "react";
import {
    Switch,
    Route,
  } from 'react-router-dom';

import Axios from 'axios'
import { AuthContext } from '../AuthContext'

import {getGeocode, getLatLng,} from "use-places-autocomplete";
import { DateTime } from 'luxon';

import TeamsHeader from "./TeamsHeader/TeamsHeader";
import DataEntry from "./DataEntry/DataEntry";
import MapInterfaceTeams from './MapInterface/MapInterfaceTeams'
import DriverRoutes from './DriverRoutes/DriverRoutes'
import DriverRouteBreakdown from './DriverRoutes/DriverRouteBreakdown'
import ShareRoutesModal from "./DriverRoutes/ShareRoutesModal";

function Dashboard() {
    const { currentUser, getTeamProfile, addTeamsRoutes, getTeamsRoute } = useContext(AuthContext);

    const [selectedDriverList, setSelectedDriverList] = useState([])
    const [userData, setUserData] = useState({})

    const [startAddress, setStartAddress] = useState('')
    const [enteredStops, setEnteredStops] = useState(['','']);
    // all addresses and coordinates are tracked (even if deleted)
    const [addressCoordinateList, setAddressCoordinateList] = useState({
        start: {name: "", coordinates: [null, null]},
        stops: [],
        end: {name: "", coordinates: [null, null]}
    });

    // full driver routes are stored here after route generation
    const [driverRoutes, setDriverRoutes] = useState(null)

    // open/close component containing each driver and their route info
    const [openDriverRoutes, setOpenDriverRoutes] = useState(false)
    const [clickedDriver, setClickedDriver] = useState(0); // driver that is currently selected

    const [isLoading, setIsLoading] = useState(false) // loading bar on build routes
    const [openShareRoutesModal, setOpenShareRoutesModal] = useState(false)

    const [selectedDate, setSelectedDate] = useState(new Date());

    const [openLoading, setOpenLoading] = useState(false)

    const [mapTrigger, setMapTrigger] = useState(0)


    useEffect(() => {
        getUserDbData()
    }, [currentUser, getTeamProfile]);

    function getUserDbData() {
        // API call to get user DB data
        const fetchData = async (email) => {
            let userDbData = await getTeamProfile(email)
  
            // check to see if user has access to teams
            if (!userDbData || !userDbData.tier || userDbData.tier < 1) {
              window.location.href = "/";
            }
  
         
          if (userDbData.startAddress) {
              let list = {...addressCoordinateList}
              list.start = userDbData.startAddress
              list.end = userDbData.endAddress
              setAddressCoordinateList(list)
              setStartAddress(userDbData.startAddress.name)
          }
          //   const savedStartAddress = JSON.parse(localStorage.getItem('startAddressTeams'));
          //   if (userDbData.startAddress && !savedStartAddress) {
          //     let list = {...addressCoordinateList}
          //     list.start = userDbData.startAddress
          //     list.end = userDbData.endAddress
          //     setAddressCoordinateList(list)
          //     setStartAddress(userDbData.startAddress.name)
          //   } else if (savedStartAddress) {
          //     let list = {...addressCoordinateList}
          //     list.start = savedStartAddress
          //     setAddressCoordinateList(list)
          //     setStartAddress(savedStartAddress)
          //   }
  
            setUserData(userDbData);
          };
      
          if (currentUser) {
              fetchData(currentUser.email);
          } else {
              console.log('No current user')
          }
    }


    useEffect(() => {

        // if there is data stored in local storage from last session
        const savedEnteredStops = JSON.parse(localStorage.getItem('enteredStopsTeams'));
        const savedAddressCoordinateList = JSON.parse(localStorage.getItem('addressCoordinateListTeams'));
        // const savedStartAddress = JSON.parse(localStorage.getItem('startAddressTeams'));
        console.log('local storage:', localStorage)
        console.log('savedEnteredStops', savedEnteredStops)
        console.log('savedAddressCoordinateList', savedAddressCoordinateList)
        if (savedEnteredStops !== null && savedAddressCoordinateList !== null && savedAddressCoordinateList.stops.length > 0) {
            setEnteredStops(savedEnteredStops)
            setAddressCoordinateList(savedAddressCoordinateList)
        }
    }, [])

    // saves (in local storage) entered stops and addressCoordinateList when addressCoordinateList changes at all 
    useEffect(() => {

        // only save to local storage if route is from today
        const today = DateTime.now().toFormat('yyyy-MM-dd');
        const selected = DateTime.fromJSDate(selectedDate).toFormat('yyyy-MM-dd');
        if (today == selected) {

            if (addressCoordinateList.stops.length > 0 || addressCoordinateList.start.name !== '') {
                localStorage.setItem('enteredStopsTeams', JSON.stringify(enteredStops));
                localStorage.setItem('addressCoordinateListTeams', JSON.stringify(addressCoordinateList));
                // localStorage.setItem('startAddressTeams', JSON.stringify(startAddress));
            }

        }
        
    }, [addressCoordinateList, enteredStops])

    // getting routes in calendar
    // useEffect(async () => {
    //     // Get the current date and format both dates as YYYY-MM-DD
    //     const today = format(new Date(), 'yyyy-MM-dd');
    //     const selected = format(selectedDate, 'yyyy-MM-dd');
    
    //     if (today != selected && currentUser && currentUser.email) {
    //         let storedDriverRoutes = await getTeamsRoute(currentUser.email, selected)
    //         console.log(storedDriverRoutes)

    //         // need to update the drivers and address coordinate list too!
    //         if (storedDriverRoutes && storedDriverRoutes.routes && storedDriverRoutes.routes.length > 0) {

    //             // fill address coordinate list and entered stops
    //             let routes = storedDriverRoutes.routes
    //             let stops = []
    //             let list = {...addressCoordinateList}
    //             let tempStart = {name: routes[0].routeOrder[0].address, coordinates: routes[0].routeOrder[0].coordinates}
    //             list.start = tempStart
    //             list.end = tempStart
    //             let tempSelectedDriverList = []
    //             for (let a = 0; a < routes.length; a++) {
    //                 tempSelectedDriverList.push(routes[a].driverInfo)
    //                 for (let b = 1; b < routes[a].routeOrder.length-1; b++) {
    //                     stops.push(routes[a].routeOrder[b].address)
    //                     list.stops.push({name: routes[a].routeOrder[b].address, coordinates: routes[a].routeOrder[b].coordinates})
    //                 }
    //             }
    //             stops.push('')
    //             setEnteredStops(stops)
    //             setAddressCoordinateList(list)
    //             setStartAddress(tempStart.name)

    //             setSelectedDriverList(tempSelectedDriverList)

    //             setDriverRoutes(storedDriverRoutes)
    //             setOpenDriverRoutes(true)

    //         } else {
    //             // clear addresses
    //             let stoplist = ['','']
    //             setEnteredStops(stoplist)

    //             let list = {...addressCoordinateList}
    //             list.stops = []

    //             setAddressCoordinateList(list)
    //             setDriverRoutes(null)
    //             setOpenDriverRoutes(false)

    //             getUserDbData() // set start address back to default
    //             setSelectedDriverList([])
    //         }
    //     } else {

    //         setOpenDriverRoutes(false)
    //         setDriverRoutes(null)

    //         getUserDbData() // set start address back to default
    //         setSelectedDriverList([])
    //     }
    //   }, [selectedDate]);

    
    async function dateSelected(datePassed) {

        // Get the current date and format both dates as YYYY-MM-DD
        const today = DateTime.now().toFormat('yyyy-MM-dd');
        const selected = DateTime.fromJSDate(datePassed).toFormat('yyyy-MM-dd');
    
        if (currentUser && currentUser.email) {
            let storedDriverRoutes = await getTeamsRoute(currentUser.email, selected)
            console.log(storedDriverRoutes)

            // need to update the drivers and address coordinate list too!
            if (storedDriverRoutes && storedDriverRoutes.routes && storedDriverRoutes.routes.length > 0) {

                // fill address coordinate list and entered stops
                let routes = storedDriverRoutes.routes
                let stops = []
                let list = {...addressCoordinateList}
                let tempStart = {name: routes[0].routeOrder[0].address, coordinates: routes[0].routeOrder[0].coordinates}
                list.start = tempStart
                list.end = tempStart
                let tempSelectedDriverList = []
                for (let a = 0; a < routes.length; a++) {
                    tempSelectedDriverList.push(routes[a].driverInfo)
                    for (let b = 1; b < routes[a].routeOrder.length-1; b++) {
                        stops.push(routes[a].routeOrder[b].address)
                        list.stops.push({name: routes[a].routeOrder[b].address, coordinates: routes[a].routeOrder[b].coordinates})
                    }
                }
                stops.push('')
                setEnteredStops(stops)
                setAddressCoordinateList(list)
                setStartAddress(tempStart.name)

                setSelectedDriverList(tempSelectedDriverList)

                setDriverRoutes(storedDriverRoutes)
                setMapTrigger((old) => old + 1)
                setOpenDriverRoutes(true)
                setClickedDriver(0)

            } else {
                // clear addresses
                let stoplist = ['','']
                setEnteredStops(stoplist)

                let list = {...addressCoordinateList}
                list.stops = []

                setAddressCoordinateList(list)
                setDriverRoutes(null)
                setMapTrigger((old) => old + 1)
                setOpenDriverRoutes(false)
                setClickedDriver(0)

                getUserDbData() // set start address back to default
                setSelectedDriverList([])
            }
        } else {

            // if (today == selected) {
            //     // clear addresses
            //     let stoplist = ['','']
            //     setEnteredStops(stoplist)

            //     let list = {...addressCoordinateList}
            //     list.stops = []
            //     setAddressCoordinateList(list)
            // }

            setOpenDriverRoutes(false)
            setClickedDriver(0)
            setDriverRoutes(null)
            setMapTrigger((old) => old + 1)

            getUserDbData() // set start address back to default
            setSelectedDriverList([])
        }

    }

    async function copyRouteToToday() {

        // one more date check, today and selected should never be equal here
        const today = DateTime.now().toFormat('yyyy-MM-dd');
        const selected = DateTime.fromJSDate(selectedDate).toFormat('yyyy-MM-dd');

        if (today != selected && driverRoutes && currentUser && currentUser.email) {

            await addTeamsRoutes(currentUser.email, today, driverRoutes)
            
            setSelectedDate(new Date()) // change current date shown on header
            dateSelected(new Date()) // call function to make appropriate changes when date changes

            alert('Route successfully copied over to today!')
        } else {
            alert('Trouble copying route.')
        }
    }

    

    function linkMethod(routeOrder, totalDistance, totalTime) {
        let baseUrl = 'http://routora.com/shared-route/'

        baseUrl += (totalDistance + '+' + totalTime + '/')

        // add addresses
        for (let i = 0; i < routeOrder.length; i++) {
            if (i == routeOrder.length-1) {
                baseUrl += encodeURIComponent(routeOrder[i].address)
            } else {
                baseUrl += encodeURIComponent(routeOrder[i].address) + '+'
            }
        }

        // add coordinates
        baseUrl += '/'
        for (let i = 0; i < routeOrder.length; i++) {
            if (i == routeOrder.length-1) {
                baseUrl += (routeOrder[i].coordinates[0] + ',' + routeOrder[i].coordinates[1])
            } else {
                baseUrl += (routeOrder[i].coordinates[0] + ',' + routeOrder[i].coordinates[1] + '+')
            }
        }

        return baseUrl
    }

    function dbMethod(routeId) {
        let baseUrl = 'http://routora.com/shared-route-id/'
        baseUrl += routeId

        return baseUrl
    }

    async function createRoutoraLink(routes) {

        let routoraRouteLink

        // if route is over 25 addresses, use db method to get stored route, else use link method where data is in url
        if (routes.routeOrder.length > 25) {

            let routeId
            // store this route for future retrieval and sharing
            try {
                // getting everything from optimized except for fullApiResponse
                let routeData = {
                    curatedLinks: routes.curatedLinks,
                    splitUrls: routes.splitUrls,
                    routeOrder: routes.routeOrder,
                    totalDistance: routes.totalDistance,
                    totalTime: routes.totalTime
                }

                // save route and pass back route id
                const response = await Axios.post(`https://api.routora.com/save-route`, { routeData });
                routeId = response.data.routeId;
                routoraRouteLink = dbMethod(routeId);
            } catch (error) {
                console.log(error)
                routoraRouteLink = dbMethod('')
            }

        } else { 

            routoraRouteLink = linkMethod(routes.routeOrder, routes.totalDistance, routes.totalTime)

        }

        return routoraRouteLink
    }
    
    async function buildRoutes() {

        if (!currentUser) {
            window.location.href = "/signin";
            return
        }

        if (selectedDriverList.length == 0) {
            alert('Please add drivers')
            return
        }
        if (startAddress.length > 0 && enteredStops[0].length > 0 && addressCoordinateList.stops.length > 0) {

            setIsLoading(true) // start loading bar

            let addressList = [startAddress]
            for (let i = 0; i < enteredStops.length; i++) {
                if (enteredStops[i].length > 0) {
                    addressList.push(enteredStops[i])
                }
            }
            addressList.push(startAddress)

            console.log(addressList)


            let stopList = []
            // loop through current address list, just stops so ignoring first and last element
            for (let a = 1; a < addressList.length-1; a++) {
                // cross reference with address coordinate list to retrieve coordinates (this list includes deleted elements so cant use this directly)
                for (let b = 0; b < addressCoordinateList.stops.length; b++) {
                    if (addressList[a] == addressCoordinateList.stops[b].name) {
                        stopList.push({address: addressCoordinateList.stops[b].name, coordinates: addressCoordinateList.stops[b].coordinates}) // update stop list parameter after match
                        break;
                    }
                }
            }
            // make sure coordinates of start and end are there
            let tempCoords
            if (addressCoordinateList.start.coordinates[0] == null) {
                tempCoords = await getCoordinates(startAddress)
                addressCoordinateList.start.coordinates = [tempCoords.lat, tempCoords.lng]
            }
            if (addressCoordinateList.end.coordinates[0] == null) {
                tempCoords = await getCoordinates(startAddress)
                addressCoordinateList.end.coordinates = [tempCoords.lat, tempCoords.lng]
            }

            let parameters = {
                startAddress: {address: startAddress, coordinates: addressCoordinateList.start.coordinates}, 
                stopList: stopList, 
                destinationAddress: {address: startAddress, coordinates: addressCoordinateList.end.coordinates},
                numDrivers: selectedDriverList.length
            }
            console.log('API PARAMETERS')
            console.log(parameters)

            try {
            
                Axios.post(`https://api.routora.com/fleet-optimization/${process.env.REACT_APP_HASHED_GOOGLE_MAPS_API}`, parameters).then(async (response) => {

                    let calculatedDriverRoutes = response.data
                    let routoraLink
                    // loop through routes and add driver information and routora links
                    for (let i = 0; i < calculatedDriverRoutes.routes.length; i++) {
                        routoraLink = await createRoutoraLink(calculatedDriverRoutes.routes[i])

                        calculatedDriverRoutes.routes[i].routoraLink = routoraLink
                        calculatedDriverRoutes.routes[i].driverInfo = selectedDriverList[i]
                    }

                    console.log(calculatedDriverRoutes)

                    setIsLoading(false) // close loading bar
                    setDriverRoutes(calculatedDriverRoutes)
                    setMapTrigger((old) => old + 1)
                    setOpenDriverRoutes(true)

                    const formattedDate = DateTime.fromJSDate(selectedDate).toFormat('yyyy-MM-dd');
                    addTeamsRoutes(currentUser.email, formattedDate, calculatedDriverRoutes)
                })

            } catch (error) {
                alert('Trouble building routes. Please ensure addresses are valid and drivers are added. Email info@routora.com for help.')
            }

        } else {

            if (startAddress.length == 0) {
                alert('Please ensure you\'ve entered a start address')
            } else if (enteredStops[0].length == 0) {
                alert('Please enter in your stops')
            } else if (addressCoordinateList.stops.length == 0) {
                alert('Map pins missing. Please click CLEAR ALL button and re-enter stops')
            }
        }

    }

    // function to optimize a singular route
    async function optimizeRoute(parameters) {
        let totalAddressCount = parameters.stopList.length + 2;
    
        if (totalAddressCount > 25) {
            // For 25+ stops, use polling technique
            try {
                const response = await Axios.post(`https://api.routora.com/optimize-app-test/${process.env.REACT_APP_HASHED_GOOGLE_MAPS_API}`, parameters);
                const taskId = response.data.taskId;
    
                return new Promise((resolve, reject) => {
                    let count = 0;
                    const interval = setInterval(async () => {
                        try {
                            const pollResponse = await Axios.post(`https://api.routora.com/poll-status/${taskId}`);
                            const pollStatus = pollResponse.data.pollStatus;
    
                            if (pollStatus !== null) {
                                clearInterval(interval);
                                deleteTaskId(taskId);

                                let routoraLink = await createRoutoraLink(pollStatus)
                                pollStatus.routoraLink = routoraLink

                                resolve(pollStatus);
                            }
                        } catch (error) {
                            clearInterval(interval);
                            reject(error);
                        }
    
                        count += 1;
                        if (count > 15) {
                            clearInterval(interval);
                            deleteTaskId(taskId);
                            reject(new Error('Timeout error: Could not connect to the server.'));
                        }
                    }, 5000);  // Poll every 5 seconds
                });
            } catch (error) {
                console.error('Error:', error);
                throw new Error('Server connection error.');
            }
        } else {
            // For routes with <=25 stops, no polling needed
            try {
                const response = await Axios.post(`https://api.routora.com/optimize-app-test/${process.env.REACT_APP_HASHED_GOOGLE_MAPS_API}`, parameters);
                if (response.data.routeOrder.length > 0) {
                    let route = response.data
                    let routoraLink = await createRoutoraLink(route)
                    route.routoraLink = routoraLink

                    return route;
                } else {
                    throw new Error('No possible route detected.');
                }
            } catch (error) {
                console.error('Error:', error);
                throw new Error('Invalid entries detected.');
            }
        }
    }
    

    function deleteTaskId(taskId) {
        // delete taskid document after use
        Axios.post(`https://api.routora.com/delete-taskid/${taskId}`).then((response) => {
            console.log('success') // .then waits for completion and runs code inside brackets

            console.log(response.data)

        }).catch((error) => {
            console.error('Error deleting taskId');
            console.error('Error:', error);
        })
    }

    async function getCoordinates(address) {
        try {
          const results = await getGeocode({ address });
          const { lat, lng } = await getLatLng(results[0]);
          return { lat, lng }
        //   panTo({ lat, lng });
        } catch (error) {
          console.log("😱 Error: ", error);
        }
    };

    return (
        <div className="flex h-screen">
            {openShareRoutesModal &&
                <ShareRoutesModal
                    driverRoutes={driverRoutes}
                    setOpenShareRoutesModal={setOpenShareRoutesModal}
                ></ShareRoutesModal>
            }
            {openLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-myteal-500 bg-opacity-20 z-50">
                    <div id="big-loader-wrapper">
                        <div id="big-loader"></div>
                    </div>
                </div>
            )}

            <TeamsHeader
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                dateSelected={dateSelected}
                copyRouteToToday={copyRouteToToday}
                driverRoutes={driverRoutes}
            ></TeamsHeader>
            <div className="flex h-full w-full">
                <DataEntry
                    startAddress={startAddress}
                    setStartAddress={setStartAddress}
                    enteredStops={enteredStops}
                    setEnteredStops={setEnteredStops}
                    addressCoordinateList={addressCoordinateList}
                    setAddressCoordinateList={setAddressCoordinateList}
                    setDriverRoutes={setDriverRoutes}
                    setMapTrigger={setMapTrigger}
                    selectedDriverList={selectedDriverList}
                    setSelectedDriverList={setSelectedDriverList}
                    userData={userData}
                    buildRoutes={buildRoutes}
                    isLoading={isLoading}
                    selectedDate={selectedDate}
                ></DataEntry>
                {openDriverRoutes &&
                    <DriverRoutes
                        openDriverRoutes={openDriverRoutes}
                        setOpenDriverRoutes={setOpenDriverRoutes}
                        setDriverRoutes={setDriverRoutes}
                        setMapTrigger={setMapTrigger}
                        driverRoutes={driverRoutes}
                        clickedDriver={clickedDriver}
                        setClickedDriver={setClickedDriver}
                        setOpenShareRoutesModal={setOpenShareRoutesModal}
                    ></DriverRoutes>
                }
                {clickedDriver != 0 &&
                    <DriverRouteBreakdown
                        clickedDriver={clickedDriver}
                        driverRoutes={driverRoutes}
                        setDriverRoutes={setDriverRoutes}
                        setMapTrigger={setMapTrigger}
                        setClickedDriver={setClickedDriver}
                        userData={userData}
                        currentUser={currentUser}
                        selectedDate={selectedDate}
                    ></DriverRouteBreakdown>
                }
                <div className="flex-grow pt-12">
                    <MapInterfaceTeams
                        addressCoordinateList={addressCoordinateList}
                        stopList={enteredStops}
                        startContent={startAddress}
                        endContent={startAddress}
                        driverRoutes={driverRoutes}
                        setDriverRoutes={setDriverRoutes}
                        setMapTrigger={setMapTrigger}
                        mapTrigger={mapTrigger}
                        mode={'d'}
                        clickedDriver={clickedDriver}
                        optimizeRoute={optimizeRoute}
                        selectedDate={selectedDate}
                        setOpenLoading={setOpenLoading}
                        linkMethod={linkMethod}
                    ></MapInterfaceTeams>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
