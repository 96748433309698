import { useSortable } from "@dnd-kit/sortable";
import { useState } from "react";
import { CSS } from "@dnd-kit/utilities";
import "./SortableItem.css"; // Ensure this is correctly imported

function SortableItem({ 
  stop, 
  index, 
  driverColor, 
  isLast, 
  openSoloGoogleMaps, 
  legTimes, 
  legDistances,
  timing
}) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: stop.id });
  const [isMouseDown, setIsMouseDown] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: isDragging ? 'grabbing' : 'grab',
    backgroundColor: isDragging || isMouseDown ? '#f0f0f0' : 'transparent',
    borderRadius: '6px'
  };

  const handleMouseDown = () => {
    setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  return (
    <li 
      ref={setNodeRef} 
      style={style} 
      {...attributes} 
      {...listeners} 
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      className={`relative flex items-start sortable-item ${isDragging ? 'is-dragging' : ''}`}
    >
      {/* Timings */}
      <div className="flex flex-col items-center mr-3">
        <div>
          <p style={{marginTop: 3}} className="text-sm font-semibold w-8 text-gray-600 text-center">{timing.split(' ')[0]}</p>
        </div>
        {/* Draggable handle */}
        <div className="handle mt-2">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            data-name="Layer 1" 
            width="20" height="20" 
            viewBox="0 0 24 24" 
            id="draggabledots"
          >
            <path fill="currentColor" d="M8.5,10a2,2,0,1,0,2,2A2,2,0,0,0,8.5,10Zm0,7a2,2,0,1,0,2,2A2,2,0,0,0,8.5,17Zm7-10a2,2,0,1,0-2-2A2,2,0,0,0,15.5,7Zm-7-4a2,2,0,1,0,2,2A2,2,0,0,0,8.5,3Zm7,14a2,2,0,1,0,2,2A2,2,0,0,0,15.5,17Zm0-7a2,2,0,1,0,2,2A2,2,0,0,0,15.5,10Z"/>
          </svg>
        </div>
      </div>

      {/* Number Circle with Line */}
      <div className="flex flex-col items-center mr-4">
        <div
          className="border text-xs text-white rounded-full w-7 h-7 flex items-center justify-center"
          style={
            (index === 0 || isLast || isDragging)
              ? {
                  borderColor: driverColor,
                  backgroundColor: driverColor,
                  color: '#FFFFFF',
                }
              : {
                  borderColor: driverColor,
                  color: driverColor,
                }
          }
        >
          {index === 0 ? (
            <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512">
              <path d="M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
              <path d="M480 256L266.89 52c-5-5.28-16.69-5.34-21.78 0L32 256M400 179V64h-48v69" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
            </svg>
          ) : isLast ? (
            <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512">
              <path d="M80 464V68.14a8 8 0 014-6.9C91.81 56.66 112.92 48 160 48c64 0 145 48 192 48a199.53 199.53 0 0077.23-15.77 2 2 0 012.77 1.85v219.36a4 4 0 01-2.39 3.65C421.37 308.7 392.33 320 352 320c-48 0-128-32-192-32s-80 16-80 16" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" />
            </svg>
          ) : index < 10 ? `0${index}` : index}
        </div>

        {!isLast && (
          <div
            className="h-16 border-l"
            style={{
              borderColor: isDragging ? '#f0f0f0' : driverColor,
              color: isDragging ? '#f0f0f0' : driverColor,
            }}
          ></div>
        )}
      </div>

      {/* Address and Info */}
      <div>
        <div style={{ marginTop: -3 }}>
          <p
            onClick={() => openSoloGoogleMaps(stop.soloUrls.googleUrl)}
            className="text-sm font-medium text-gray-600 hover:text-gray-800 underline underline-offset-1 cursor-pointer"
          >
            {stop.address}
          </p>
        </div>
        {index !== 0 && (
          <div className="flex mt-1">
            <div className="text-xs text-gray-600">
              {legTimes[index - 1] !== undefined ? legTimes[index - 1].toFixed(0) + " min" : "-- min"}
            </div>
            <div style={{ color: driverColor, marginTop: -2 }} className="text-sm mx-2">|</div>
            <div className="text-xs text-gray-600">
              {legDistances[index - 1] !== undefined ? legDistances[index - 1].toFixed(1) + " mi" : "-- mi"}
            </div>
          </div>
        )}
      </div>
    </li>
  );
}

export default SortableItem;
