import { useState, useEffect, useContext } from "react";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
  arrayMove
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import SortableItem from "./SortableItem";
import { AuthContext } from '../../AuthContext'
import { DateTime } from 'luxon';


function DriverRouteBreakdown(props) {

  const { addTeamsRoutes } = useContext(AuthContext);

  const [routeInfo, setRouteInfo] = useState(null); // including rearranged stops
  const [timings, setTimings] = useState([])


  function closeBreakdown() {
    props.setClickedDriver(0);
  }

  function openSoloGoogleMaps(link) {
    window.open(link, '_blank');
  }

  function calculateTimings(routeList) {
    let startTime = props.userData.startTime; // e.g., "08:00"
    let timeAtStop = props.userData.averageStopTime; // e.g., 5 (minutes)
  
    if (!startTime || !routeList || routeList.legTimes.length === 0) return;
  
    // Parse the start time
    let [startHour, startMinute] = startTime.split(":").map(Number);
    let totalMinutes = startHour * 60 + startMinute; // Convert start time to total minutes since midnight
  
    const timingsArray = [];
  
    // Calculate ETA for each stop
    for (let i = 0; i < routeList.routeOrder.length; i++) {
      // Convert total minutes to hours and minutes
      let hour = Math.floor(totalMinutes / 60) % 12 || 12; // Convert back to 12-hour format
      let minute = totalMinutes % 60;
      let period = totalMinutes / 60 >= 12 ? "PM" : "AM";
  
      // Format the time as "hh:mm AM/PM"
      let formattedTime = `${hour}:${minute.toString().padStart(2, "0")} ${period}`;
      timingsArray.push(formattedTime);
  
      // Update totalMinutes only for intermediate stops
      if (i < routeList.legTimes.length) {
        totalMinutes += Math.round(routeList.legTimes[i]); // Add travel time
        if (i > 0) {
          totalMinutes += timeAtStop; // Add stop time for intermediate stops
        }
      }
    }
  
    // Update the state with calculated timings
    setTimings(timingsArray);
  }
  
  

  useEffect(() => {
    if (props.clickedDriver && props.driverRoutes) {

      // Match driver id to clickedDriver value, and retrieve route info
      let routeList = props.driverRoutes.routes;
      for (let i = 0; i < routeList.length; i++) {
        if (routeList[i].driverInfo.id == props.clickedDriver) {

          calculateTimings(routeList[i])

          // Add an `id` field to each stop for DnD Kit (if not already present)
          const stopsWithIds = routeList[i].routeOrder.map((stop, index) => ({
            ...stop,
            id: `${stop.address}-${index}`
          }));
          setRouteInfo({
            ...routeList[i],
            routeOrder: stopsWithIds
          });
          break;
        }
      }

    }
  }, [props.clickedDriver, props.driverRoutes]);


  /* 
    UPDATING LINKS AFTER REARRANGING
  */
 // make link ready
  function createGoogleLink(formattedRouteOrder) {
    let googleUrl = ''
    googleUrl = 'https://www.google.com/maps/dir/' + formattedRouteOrder[0] + '/'
    for (let i = 1; i < formattedRouteOrder.length-1; i++) {
      googleUrl += formattedRouteOrder[i] + '/'
    }
    googleUrl += formattedRouteOrder[formattedRouteOrder.length-1] + '/'

    return googleUrl
  }
  function createAppleLink(formattedRouteOrder) {
    let appleUrl = ''
    appleUrl = 'http://maps.apple.com/?saddr=' + formattedRouteOrder[0]
    for (let i = 1; i < formattedRouteOrder.length-1; i++) {
      appleUrl += '&daddr=' + formattedRouteOrder[i]
    }
    appleUrl += '&daddr=' + formattedRouteOrder[formattedRouteOrder.length-1] + '&dirflg=d'

    return appleUrl
  }
  function updateLinks(routeOrder) {
    let formattedRouteOrder = []
    for (let i = 0; i < routeOrder.length; i++) {
      formattedRouteOrder.push(encodeURIComponent(routeOrder[i].address))
    }
    let googleUrl = createGoogleLink(formattedRouteOrder)
    let appleUrl = createAppleLink(formattedRouteOrder)

    let splitUrls = getSplitUrls(formattedRouteOrder)
    
    return {splitUrls: splitUrls, curatedLinks: {appleUrl: appleUrl, googleUrl: googleUrl, wazeUrl: googleUrl}}
  }
  function getSplitUrls(formattedRouteOrder) {
    let splitUrls = {
      google: null,
      apple: null,
      waze: null
    }
    // 15 is apple's cap
    if (formattedRouteOrder.length <= 15) {
      return splitUrls
    }
    let maxStopsPerRoute = 15;
    let splitAppleUrls = [];
    // Split the addresses into chunks of maxStopsPerRoute
    for (let i = 0; i < formattedRouteOrder.length; i += maxStopsPerRoute - 1) {
        let chunk = formattedRouteOrder.slice(i, i + maxStopsPerRoute);
        // Ensure that we are not exceeding the array length
        let data = createAppleLink(chunk);
        splitAppleUrls.push(data);
    }
    splitUrls.apple = splitAppleUrls

    // 25 is google's cap
    if (formattedRouteOrder.length <= 25) {
      return splitUrls
    }
    maxStopsPerRoute = 25;
    let splitGoogleUrls = [];
    // Split the addresses into chunks of maxStopsPerRoute
    for (let i = 0; i < formattedRouteOrder.length; i += maxStopsPerRoute - 1) {
        let chunk = formattedRouteOrder.slice(i, i + maxStopsPerRoute);
        // Ensure that we are not exceeding the array length
        let data = createGoogleLink(chunk);
        splitGoogleUrls.push(data);
    }
    splitUrls.google = splitGoogleUrls

    return splitUrls
  }



  // Setup DnD sensors
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    if (!over) return;
  
    if (active.id !== over.id) {
      setRouteInfo((prev) => {
        const oldIndex = prev.routeOrder.findIndex((item) => item.id === active.id);
        const newIndex = prev.routeOrder.findIndex((item) => item.id === over.id);
        
        // Update the routeOrder state
        const updatedRouteOrder = arrayMove(prev.routeOrder, oldIndex, newIndex);

        // update links
        let newLinks = updateLinks(updatedRouteOrder)
  
        // Update props.driverRoutes
        const updatedDriverRoutes = props.driverRoutes.routes.map((route) => {
          if (route.driverInfo.id === props.clickedDriver) {
            return {
              ...route,
              routeOrder: updatedRouteOrder,
              curatedLinks: newLinks.curatedLinks,
              splitUrls: newLinks.splitUrls,
            };
          }
          return route;
        });
  
        // Notify parent of the change
        let newDriverRoutes = { routes: updatedDriverRoutes }
        props.setDriverRoutes(newDriverRoutes);
        props.setMapTrigger((old) => old + 1)

        // update saved route
        const formattedDate = DateTime.fromJSDate(props.selectedDate).toFormat('yyyy-MM-dd');
        if (props.currentUser && formattedDate) {
          addTeamsRoutes(props.currentUser.email, formattedDate, newDriverRoutes)
        }

        console.log('COMPARE')
        console.log(newDriverRoutes)
        console.log(props.driverRoutes)
  
        return {
          ...prev,
          routeOrder: updatedRouteOrder,
        };
      });
    }
  }
  

  return (
    <div
      className="relative bg-gray-100 h-screen overflow-y-auto pt-12 pb-5 px-5 bg-white z-10 border-solid border-r border-gray-300"
      style={{ boxShadow: "inset 12px 0 10px -10px rgba(0, 0, 0, 0.1)", width: '350px' }}
    >
      <div className="driversRouteBreakdownCloseBtn z-20">
        <button className="text-gray-400" onClick={closeBreakdown}>
          <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 512 512">
            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"/>
          </svg>
        </button>
      </div>

      {routeInfo ? (
        <div style={{ marginTop: 68 }}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
          >
            <SortableContext
              items={routeInfo.routeOrder.map(stop => stop.id)}
              strategy={verticalListSortingStrategy}
            >
              <ul>
                {routeInfo.routeOrder.map((stop, index) => (
                  <SortableItem
                    key={stop.id}
                    stop={stop}
                    index={index}
                    driverColor={routeInfo.driverInfo.colorCode}
                    isLast={index === routeInfo.routeOrder.length - 1}
                    openSoloGoogleMaps={openSoloGoogleMaps}
                    legTimes={routeInfo.legTimes}
                    legDistances={routeInfo.legDistances}
                    timing={timings[index]}
                  />
                ))}
              </ul>
            </SortableContext>
          </DndContext>
        </div>
      ) : (
        <div>Loading route information...</div>
      )}
    </div>
  );
}

export default DriverRouteBreakdown;
