import React, { useState, useEffect, useRef } from 'react';
import {getGeocode, getLatLng,} from "use-places-autocomplete";
import './ListModal.css'
import Axios from 'axios'

import Import from './Import.js'
import ColumnSelectionModal from './ColumnSelectionModal.js'

import StopsAutocomplete from './StopsAutocomplete.js'

function ImportModal(props) {

    const [validationResults, setValidationResults] = useState([])
    const [addresses, setAddresses] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [openSelectModal, setOpenSelectModal] = useState(false)
    const [columns, setColumns] = useState([])
    const [json, setJson] = useState([])

    async function getCoordinates(address) {
        try {
          const results = await getGeocode({ address });
          const { lat, lng } = await getLatLng(results[0]);
          return { lat, lng }
        //   panTo({ lat, lng });
        } catch (error) {
          console.log("😱 Error: ", error);
        }
    };

    const handleAddressesLoaded = (loadedAddresses) => {
        setAddresses(loadedAddresses);
        console.log('LOADED ADDRESSES -> ', loadedAddresses)
        
        submitAddresses(loadedAddresses)
    };

    const handleCheckboxChange = (id) => {
        setValidationResults(validationResults.map(result => 
            result.id === id ? { ...result, selected: !result.selected } : result
        ));
    };

    let count = 0
    for (let i = 0; i < validationResults.length; i++) {
        if (validationResults[i].selected) {
            count++
        }
    }

    const setAddressContent = (value, id) => {
        setValidationResults(validationResults.map(result => 
            result.id === id ? { ...result, formattedAddress: value } : result
        ));
    }
    
    async function handleAddressChange(value, id) {
        console.log('SELECTED ADDRESS -> ' + value)
        let tempCoords = await getCoordinates(value)
        console.log(tempCoords)

        setValidationResults(validationResults.map(result => 
            result.id === id ? { ...result, coordinates: tempCoords, formattedAddress: value, selected: true, edit: false } : result
        ));
    }

    const editClicked = (id) => {
        setValidationResults(validationResults.map(result => 
            result.id === id ? { ...result, edit: true } : result
        ));
    };

    function columnSelected(selectedColumn) {
        console.log('Selected column:', selectedColumn);

        const addresses = json.map((row) => row[selectedColumn]);
        handleAddressesLoaded(addresses);
    }

    function submitAddresses(loadedAddresses) {

        setIsLoading(true)
        if (!props.isTeams && loadedAddresses.length > 100) {
            alert('Must be less than 100 lines')
            setIsLoading(false)
        } else if (props.isTeams && loadedAddresses.length > 200) {
            alert('Must be less than 200 lines')
            setIsLoading(false)
        } else {

            Axios.post('https://api.routora.com/validate-addresses', {addressArr: loadedAddresses}).then((response) => {

                console.log('success')

                let results = response.data.validationResults
                // taking the results and marking each as selected, unless unresolved (empty string)
                for (let i = 0; i < results.length; i++) {
                    // set id, edit status, and selected status
                    results[i].id = i;
                    results[i].edit = false;
                    if (results[i].formattedAddress.length > 0) {
                        results[i].selected = true;
                    } else {
                        results[i].selected = false;
                    }
                }

                console.log(results)

                setValidationResults(results)
                setIsLoading(false)
                goValidationResults()

            }).catch((error) => {
                console.log("ERROR IN VALIDATION API -> " + error)
            })

        }

    }

    function closeModal() {
        props.setImportType('address')
        props.setButtonTextColors({address: '#00b28b', url: '#7F7F7F', list: '#7F7F7F', import: '#7F7F7F'})
        props.setButtonBGColors({address: '#e0fff8', url: '#FFFFFF', list: '#FFFFFF', import: '#FFFFFF'})
        props.setImportModalOpen(false)
    }

    function goValidationResults() {
        // scroll to validation results
        const modal = document.getElementById("importModal");
        const targetDiv = document.getElementById("importValidationResults");

        modal.scrollTop = targetDiv.offsetTop;
    }

    function importSelected() {
        console.log(validationResults)

        // first check if any repeat addresses
        let breakFunc = false
        for (let a = 0; a < validationResults.length-1; a++) {
            for (let b = a+1; b < validationResults.length; b++) {
                if ((validationResults[a].formattedAddress === validationResults[b].formattedAddress) &&
                    validationResults[a].selected && validationResults[b].selected) {
                    
                    let alertStr = 'Address: \'' + validationResults[a].formattedAddress + '\' repeated more than once (#' + (a+1) + ' and #' + (b+1) + '). Please resolve before importing'
                    alert(alertStr)

                    breakFunc = true
                    break
                }
            }
            if (breakFunc) {
                break
            }
        }

        
        if (!breakFunc) {
            props.submitList(validationResults)
            props.setImportModalOpen(false)
        }
    }


    const loadingPhrases = [
        "Uploading File...",
        "Scanning File...", 
        "Processing Addresses...",
        "Validating Data...",
        "Standardizing Addresses...",
        "Fetching Location Details...",
        "Loading Addresses...",
    ];
    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
    useEffect(() => {
        let interval;

        if (isLoading) {
            interval = setInterval(() => {
                setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % loadingPhrases.length);
            }, 200); // Rotate every 500ms
        } else {
            setCurrentPhraseIndex(0); // Reset index when loading stops
        }

        return () => {
            clearInterval(interval); // Clean up interval on component unmount or when isLoading changes
        };
    }, [isLoading])

    let loadingDisplay = ( <div></div>)
    if (!isLoading) {
        loadingDisplay = <p className="h-2"></p>
    } else {
        loadingDisplay = (
            <div className="flex mt-2"> 
                <div style={{marginTop: '2px'}}>
                    <div class="loader">
                        <div class="bigger-loading-circle"></div>
                    </div>
                </div>
                <p className="ml-2">{loadingPhrases[currentPhraseIndex]}</p>
            </div>
        )
    }


    return (
        <div className="listModalBackground">
            {openSelectModal &&
                <ColumnSelectionModal
                    columns={columns}
                    setOpenSelectModal={setOpenSelectModal}
                    columnSelected={columnSelected}
                ></ColumnSelectionModal>
            }
            <div className="listModalContainer relative">
                    <div className="h-7 w-7 bg-gray-400 hover:bg-gray-600 absolute -right-2 -top-2 rounded-full cursor-pointer" onClick={closeModal}>
                        <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="times"><path fill="#FFFFFF" d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path></svg>
                    </div>
                    <div id="importModal" className="listModalContainerInner">
                        <p className="text-gray-700 font-semibold mb-4">Please upload an Excel or CSV file with a column titled 'Address'.</p>
                        <div>
                            <Import 
                                onAddressesLoaded={handleAddressesLoaded}
                                setColumns={setColumns} 
                                setJson={setJson}
                                setOpenSelectModal={setOpenSelectModal}
                            />
                        </div>

                        {loadingDisplay}
                        {validationResults.length > 0 &&
                            <div id="importValidationResults" className="w-full mt-5 mb-20">
                                {validationResults.map((result, i) =>

                                    <div className="flex justify-between w-full my-3">
                                        <div className="flex">
                                            { result.formattedAddress.length > 0 ?
                                                <input
                                                    type="checkbox"
                                                    className="mr-5 mt-4 cursor-pointer"
                                                    checked={result.selected}
                                                    onChange={() => handleCheckboxChange(result.id)}
                                                />
                                                :
                                                <div className="mr-5 mt-4">
                                                    <div className="bg-red-500 w-4 h-4">
                                                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="times"><path fill="#FFFFFF" d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path></svg>
                                                    </div>
                                                </div>
                                            }
                                            { !result.edit ?
                                                <div>
                                                    <p className="text-gray-500">{result.address}</p>
                                                    {/* if formattedAddress exists or is selected (ok because cant select empty formattedAddres which is why its not &&) */}
                                                    {result.formattedAddress.length > 0 || result.selected ?
                                                        <p className="font-semibold text-gray-700">{result.formattedAddress}</p>
                                                        :
                                                        <p className="text-red-500">Unresolved</p>
                                                    }
                                                </div>
                                                :
                                                <div className="relative">
                                                <StopsAutocomplete
                                                    class="form-input text-gray-800 px-3 py-2 pr-12 text-sm float-right hover:bg-gray-100"
                                                    placeholder="Add Stop"
                                                    content={result.formattedAddress}
                                                    handleChange={value => setAddressContent(value, result.id)}
                                                    handleSelect={value => handleAddressChange(value, result.id)}
                                                ></StopsAutocomplete>
                                                </div>
                                            }
                                        </div>
                                        <div onClick={() => editClicked(result.id)} className="text-right cursor-pointer">
                                            <svg className="w-6 h-6 mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="edit"><path fill="#4C4C4C" d="M21,12a1,1,0,0,0-1,1v6a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4h6a1,1,0,0,0,0-2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12ZM6,12.76V17a1,1,0,0,0,1,1h4.24a1,1,0,0,0,.71-.29l6.92-6.93h0L21.71,8a1,1,0,0,0,0-1.42L17.47,2.29a1,1,0,0,0-1.42,0L13.23,5.12h0L6.29,12.05A1,1,0,0,0,6,12.76ZM16.76,4.41l2.83,2.83L18.17,8.66,15.34,5.83ZM8,13.17l5.93-5.93,2.83,2.83L10.83,16H8Z"></path></svg>
                                        </div>
                                    </div>

                                )}
                                <div className="import-button bg-myteal-500 hover:bg-myteal-600" >
                                    <p onClick={importSelected} className="import-text">Import Selected ({count})</p>
                                </div>
                            </div>
                        }
                    </div>
            </div>
        </div>
    );
}

export default ImportModal;